import React from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { ConfirmModal } from "..";
import {
  BILLING_TYPE_OPTIONS,
  translateBillingType,
} from "../utils/billing_types";
import { FaExpeditedssl } from "react-icons/fa";
import { CheckoutPropType } from "../utils/CheckoutPropType";
import { BiCheckShield } from "react-icons/bi";
import { CheckoutFormOptions } from "./CheckoutFormOptions";
import { FormField } from "./FormField";
import { ThemeContext } from "../utils/themeContext";
import CheckoutUtils from "../utils/CheckoutUtils";
import { CheckoutFormCustomFields } from "./CheckoutFormCustomFields";
import { useUTMParameters } from "use-utm-parameters";

const BRASIL = "BR";
const colorSuccess = "#009358";

export const CheckoutFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  validateField,
  errors,
  touched,
  isValid,
  countries,
  checkout = {},
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const theme = React.useContext(ThemeContext);
  const params = useUTMParameters(global.location.search);

  const isBrazilian = values?.country.toUpperCase() === BRASIL;

  const canChooseCountry = checkout?.payment_types?.includes("CREDIT_CARD")
    ? true
    : false;

  let installments = [];

  const customFields = checkout?.custom_fields_available;

  if (checkout?.installments?.length) {
    installments = checkout?.installments;

    if (!isBrazilian) {
      installments = [installments[0]];
    }
  }

  const onConfirmModal = () => {
    setModalShow(false);
    handleSubmit();
  };

  const onCloseModal = () => setModalShow(false);

  const onSubmit = e => {
    e.preventDefault();
    setFieldValue("utm_params", params);

    if (isValid && touched.name) {
      setModalShow(true);
    } else {
      handleSubmit();
    }
  };

  const alertErrors = errors.global_error || errors.checkout_id;

  const countryOptions = React.useMemo(
    () =>
      countries?.map(i => (
        <option key={i.Code} value={i.Code}>
          {i.Name}
        </option>
      )),
    [countries],
  );

  const handleInstallmentChange = React.useCallback(
    e => {
      if (!isBrazilian) {
        setFieldValue("billing_type", "CREDIT_CARD");
      }

      handleChange(e);
    },
    [isBrazilian, handleChange, setFieldValue],
  );

  const handleCountryChange = React.useCallback(
    e => {
      if (e.target.value.toString().toUpperCase() !== BRASIL) {
        setFieldValue("installment_count", 1);
        setFieldValue("billing_type", "CREDIT_CARD");
        setTimeout(() => validateField("installment_count"), 100);
      }

      handleChange(e);
    },
    [handleChange, setFieldValue, validateField],
  );

  const submitButtonText = () => {
    if (values.billing_type === BILLING_TYPE_OPTIONS.pix) {
      return "Gerar PIX e pagar";
    }

    if (values.billing_type === BILLING_TYPE_OPTIONS.boleto) {
      return "Gerar Boleto e pagar";
    }

    return "Pagar e receber meu produto";
  };

  return (
    <div>
      <ConfirmModal
        centered={true}
        fullscreen="md-down"
        onConfirm={onConfirmModal}
        onClose={onCloseModal}
        show={modalShow}
        text={
          <div
            className="d-flex align-items-center fs-large-text-sm"
            style={{ minHeight: "100%" }}
          >
            <div>
              Você escolheu:{" "}
              <strong className="text-warning">
                {translateBillingType(values.billing_type)} em{" "}
                {values.installment_count} parcela
                {values.installment_count > 1 ? "s" : ""}
              </strong>
              . Deseja continuar?
            </div>
          </div>
        }
      />

      <input
        name="billing_type"
        type="hidden"
        defaultValue={values.billing_type}
      />

      <div>
        <Form onSubmit={onSubmit} id="form-checkout-fields">
          <h5 className="visually-hidden visually-hidden-focusable">
            Seus dados
          </h5>

          <Container>
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                <Card body p={2}>
                  {canChooseCountry && (
                    <FloatingLabel controlId="country" label="Nacionalidade">
                      <Form.Select
                        value={values.country}
                        onBlur={handleBlur}
                        onChange={handleCountryChange}
                        aria-label="Nacionalidade"
                        className="mb-3"
                      >
                        {countryOptions}
                      </Form.Select>
                    </FloatingLabel>
                  )}

                  {!isBrazilian && (
                    <p className="text-primary text-left">
                      * Para pagamentos com moedas estrangeiras, a única opção
                      disponível é cartão de crédito. Ao prosseguir, você
                      efetuará o pagamento à vista do valor integral.
                    </p>
                  )}

                  <FormField
                    type="text"
                    label={
                      <>
                        Nome completo{" "}
                        <div
                          className="fw-light m-0 p-0 text-body-secondary"
                          style={{ fontSize: 14 }}
                        >
                          (Preencher o nome exatamente como consta em seu
                          documento de identificação).
                        </div>
                      </>
                    }
                    name="name"
                    placeholder="Digite seu nome completo"
                    error={errors.name}
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.name}
                  />

                  <div className="container-fluid px-0">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <FormField
                          type="email"
                          label="Digite seu e-mail pessoal"
                          placeholder="Digite seu e-mail pessoal"
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.email}
                          fieldAttributes={{
                            readOnly: !!values?.checkout_unlock,
                          }}
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                        <FormField
                          type="email"
                          label="Confirme seu e-mail"
                          placeholder="Deve ser igual ao campo anterior"
                          name="email_confirmation"
                          error={errors.email_confirmation}
                          touched={touched.email_confirmation}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.email_confirmation}
                          fieldAttributes={{
                            readOnly: !!values?.checkout_unlock,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {isBrazilian && (
                    <FormField
                      type="text"
                      label={
                        <>
                          CPF ou CNPJ{" "}
                          <span
                            className="fw-light m-0 p-0 text-body-secondary"
                            style={{ fontSize: 14 }}
                          >
                            (Digite o documento do nome completo acima)
                          </span>
                        </>
                      }
                      name="doc_number"
                      placeholder="Somente números"
                      error={errors.doc_number}
                      touched={touched.doc_number}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.doc_number}
                    />
                  )}

                  <FormField
                    label={
                      <>
                        Telefone Celular{" "}
                        <span
                          className="fw-light m-0 p-0 text-body-secondary"
                          style={{ fontSize: 14 }}
                        >
                          (De preferência com WhatsApp)
                        </span>
                      </>
                    }
                    placeholder="Apenas números"
                    name="phone_number"
                    error={errors.phone_number}
                    touched={touched.phone_number}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.phone_number}
                  />

                  {values?.checkout_unlock && (
                    <Alert variant="warning">
                      Este e-mail não pode ser alterado, pois está atrelado a
                      validação da compra. Se deseja comprar com outro e-mail,{" "}
                      <Link
                        to={`${CheckoutUtils.buildURL(
                          checkout?.id,
                          checkout?.slug,
                        )}/clear_unlock`}
                      >
                        clique aqui
                      </Link>
                      .
                    </Alert>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>

          {values.billing_type && checkout?.payment_types?.length > 1 && (
            <div>
              {isBrazilian && (
                <CheckoutFormOptions
                  onSelect={value => setFieldValue("billing_type", value)}
                  options={checkout?.payment_types}
                />
              )}

              <Container>
                <Row className="justify-content-md-center">
                  <Col md={9} lg={7}>
                    {errors.billing_type && touched.billing_type ? (
                      <p className="text-danger">{errors.billing_type}</p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </div>
          )}

          {installments?.length > 1 && (
            <div>
              <h5 className="my-3">Escolha sua parcela:</h5>
              <Container>
                <Row className="justify-content-center">
                  <Col lg={7}>
                    {!isBrazilian && (
                      <Alert variant="info">
                        Para não residentes do Brasil, o pagamento é aceito
                        apenas via cartão de crédito à vista.
                      </Alert>
                    )}

                    <fieldset>
                      <Card body>
                        <Row className="mb-2">
                          {installments.map(({ description, installment }) => (
                            <Col key={description} sm={6}>
                              <Form.Check
                                type="radio"
                                id={`installment-count-${installment}`}
                                className="text-left"
                              >
                                <Form.Check.Input
                                  type="radio"
                                  name="installment_count"
                                  onChange={handleInstallmentChange}
                                  value={installment}
                                  onBlur={handleBlur}
                                  checked={
                                    values.installment_count.toString() ===
                                    installment.toString()
                                  }
                                />
                                <Form.Check.Label className="px-2">
                                  {description.replace(".", ",")}
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </fieldset>
                  </Col>
                </Row>
              </Container>
            </div>
          )}

          {installments?.length > 1 && (
            <p className="text-center fs-6 fw-light">
              * Parcelamento com tarifa de{" "}
              {parseFloat(checkout.interest_rate).toFixed(2)}% a.m.
            </p>
          )}

          {errors.installment_count && (
            <Container className="mt-2">
              <Row>
                <Col>
                  <p className="text-danger mb-0 text-center mb-0">
                    {errors.installment_count}
                  </p>
                </Col>
              </Row>
            </Container>
          )}

          {!!customFields?.length && (
            <CheckoutFormCustomFields
              errors={errors}
              values={values}
              customFields={customFields}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          )}

          <Container>
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

                <div className="d-grid gap-2">
                  <Button
                    disabled={isSubmitting}
                    variant="success"
                    type="submit"
                    className="mt-3 p-3"
                  >
                    {isSubmitting && (
                      <div>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">
                            Processando...
                          </span>
                        </Spinner>
                      </div>
                    )}

                    {!isSubmitting && (
                      <div>
                        <FaExpeditedssl /> {submitButtonText()}
                      </div>
                    )}
                  </Button>
                </div>

                <Col className="text-success text-center m-3 fs-6  lh-1">
                  <BiCheckShield fontSize={40} color={colorSuccess} /> Compra
                  100% Segura.
                </Col>
              </Col>
            </Row>
          </Container>

          <p className="fs-6 m-0 text-center">
            Ao continuar, você concorda com os{" "}
            <a href={theme.termsURL} target="_blank" rel="noreferrer">
              termos de uso
            </a>{" "}
            e{" "}
            <a href={theme.privacyPolicyURL} target="_blank" rel="noreferrer">
              políticas de privacidade
            </a>
          </p>

          <p className="fs-6">
            Depois do pagamento, fique atento ao seu e-mail para receber os
            dados de acesso ao produto (verifique também a caixa de SPAM).
            <br />
            {theme.footerText}
          </p>

          <Container>
            <Row className="justify-content-md-center"></Row>
          </Container>
        </Form>
      </div>
    </div>
  );
};

CheckoutFormFields.propTypes = {
  checkout: CheckoutPropType,
};
