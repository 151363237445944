import React from "react";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";

import {
  CheckoutDetails,
  CheckoutForm,
  CheckoutHeader,
  TopBar,
  Loading,
  Whatsapp,
} from "../components";

import { useCheckout } from "../hooks/useCheckout";
import { CheckoutLockedUtil } from "../components/CheckoutLocked/utils/CheckoutLockedUtil";

function PageDetails() {
  const { getCheckout, loading, checkout, error } = useCheckout();
  const [organization, setOrganization] = React.useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getCheckout(), []);

  React.useEffect(() => {
    setOrganization(checkout?.organization);
  }, [checkout, setOrganization]);

  const checkoutData = checkout?.checkout;

  if (loading || !checkoutData) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  if (!CheckoutLockedUtil.userCanPurchase(checkoutData)) {
    return <Redirect to={CheckoutLockedUtil.checkoutLockedURL(checkoutData)} />;
  }

  return (
    <div>
      <Whatsapp url={checkout?.checkout?.whatsapp_support_line} />
      <TopBar />

      <Container className="text-center">
        <CheckoutHeader
          checkout={checkout?.checkout}
          organization={organization}
        />
        <CheckoutDetails checkout={checkout?.checkout} />
        <CheckoutForm
          checkout={checkout?.checkout}
          countries={checkout?.countries}
        />
      </Container>
    </div>
  );
}

export default PageDetails;
