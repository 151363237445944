import { withFormik } from "formik";
import { CheckoutLockedApoloServiceFormFields } from "./CheckoutLockedApoloServiceFormFields";
import { api } from "../../api";
import { CheckoutLockedUtil } from "./utils/CheckoutLockedUtil";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

export const CheckoutLockedApoloServiceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ checkout }) => ({
    user_email: "",
    checkout_id: checkout?.id,
    global_error: "",
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.user_email) {
      errors.user_email =
        "Seu e-mail é indispensável para prosseguir. Favor digitar um e-mail válido.";
    }

    return errors;
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    try {
      const request = await api.post(`/checkout/${values.checkout_id}/unlock`, {
        checkout_unlock: {
          data: {
            user_email: values.user_email,
          },
        },
      });

      if (request.data?.token) {
        setSubmitting(true);
        global.Rollbar.info("Checkout Unlock Success", {
          rollbarData: request.data,
        });

        CheckoutLockedUtil.set(request.data?.token, values.user_email);

        window.location.href = `/${values.checkout_id}/compra-autorizada`;
      } else {
        setSubmitting(false);

        setFieldError(
          "global_error",
          "Checkout não pode ser desbloqueado, informe um e-mail com o certificado válido.",
        );
      }
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        // All fields are returned with their own key and error, but for us, we only care about true/false here for this service.
        if (error.response.status === 422) {
          setFieldError(
            "user_email",
            "Seu e-mail não pode ser validado como um certificado válido TRG. Se você acredita que isso é um erro, entre em contato conosco o quanto antes.",
          );

          if (error.response.error) {
            setFieldError("global_error", error.response.error);
          }
        } else {
          setFieldError("global_error", defaultErrorMessage);
          global.Rollbar.warning("Global Error", error.toJSON());
        }
      } else {
        setFieldError("global_error", defaultErrorMessage);
        global.Rollbar.warning("Global Error", error.toJSON());
      }
    }
  },

  displayName: "CheckoutLockedApoloServiceForm",
})(CheckoutLockedApoloServiceFormFields);
