import { withFormik } from "formik";
import { api } from "../../api";
import {
  isValidCPF,
  isValidCNPJ,
  isValidCEP,
} from "@brazilian-utils/brazilian-utils";
import { CreditCardFormFields } from "..";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";
const BRASIL = "BR";

export const CreditCardForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ payment, referenceId }) => ({
    gateway_id: payment?.payment?.gateway_id,
    billing_type: "CREDIT_CARD",
    holder_name: payment?.customer?.name || "",
    holder_doc_number: payment?.customer?.doc_number,
    holder_phone_number: payment?.customer?.phone_number,
    address_country:
      payment?.customer?.country.toString().toUpperCase() || BRASIL,
    address_zipcode: "",
    address_number: "",
    address_complement: "",
    credit_card_holder_name: "",
    credit_card_number: "",
    credit_card_expiry_month: "",
    credit_card_expiry_year: "",
    credit_card_ccv: "",
    reference_id: referenceId || "NOT_FOUND",
  }),

  mapPropsToErrors: () => ({}),

  // Custom sync validation
  validate: async values => {
    const errors = {};
    const baseErrorDocNumber =
      "Documento inválido, por favor digite um CPF ou CNPJ válido. Use somente números. Se você for de outro país, selecione outro país na lista acima.";

    if (!values.credit_card_holder_name) {
      errors.credit_card_holder_name =
        "Preencha o nome como aparece no seu cartão.";
    }

    if (!values.credit_card_number) {
      errors.credit_card_number =
        "Preencha o número do seu cartão (apenas dígitos).";
    }

    if (!values.holder_name) {
      errors.holder_name = "Preencha o nome completo do comprador.";
    }

    if (!values.credit_card_expiry_month) {
      errors.credit_card_expiry_month =
        "Mês de validade com 2 dígitos apenas. Ex: 01";
    }

    if (!values.credit_card_expiry_year) {
      errors.credit_card_expiry_year =
        "Ano de validade com 2 dígitos apenas. Ex: 27";
    }

    if (!values.credit_card_ccv) {
      errors.credit_card_ccv = "Código de segurança é obrigatório.";
    }

    if (values?.address_country === BRASIL && !values?.address_number) {
      errors.address_number = "Obrigatório";
    }

    if (values.address_country === BRASIL) {
      if (!values.holder_doc_number) {
        errors.holder_doc_number = baseErrorDocNumber;
      } else if (
        !isValidCPF(values.holder_doc_number) &&
        !isValidCNPJ(values.holder_doc_number)
      ) {
        errors.holder_doc_number = baseErrorDocNumber;
      }
    }

    if (!values.holder_phone_number) {
      if (values.address_country === BRASIL) {
        errors.holder_phone_number =
          "Favor preencher seu número de celular apenas com números começando com o DDD.";
      } else {
        errors.holder_phone_number =
          "Favor preencher seu número de celular com o código do país e apenas números.";
      }
    }

    if (
      values?.address_country === BRASIL &&
      !isValidCEP(values?.address_zipcode)
    ) {
      errors.address_zipcode =
        "Esta mensagem desaparecerá quando o CEP ficar válido. Se seu cartão for estrangeiro, selecione outro país acima.";
    }

    return errors;
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    try {
      const request = await api.post("/payments", { payment: values });
      const data = request?.data;
      const payment = data?.payment;

      if (payment?.status === "paid") {
        setSubmitting(true);
        window.location.href = `/payments/${payment.gateway_id}/success?reference_id=${values.reference_id}&billing_type=CREDIT_CARD`;
      } else {
        setSubmitting(false);
        setFieldError("global_errors", defaultErrorMessage);
      }
    } catch (error) {
      setSubmitting(false);

      if (error.response) {
        if (error.response.status === 422) {
          const data = error.response.data.errors;

          if (data) {
            Object.keys(data).map(errorKey =>
              setFieldError(errorKey, data[errorKey].join(", ")),
            );
          }

          if (error.response.error) {
            setFieldError("global_errors", error.response.error);
          }
        } else {
          setFieldError("global_errors", defaultErrorMessage);
          global.Rollbar.warning("Global Error", error.toJSON());
        }
      } else {
        setFieldError("global_errors", defaultErrorMessage);
        global.Rollbar.warning("Global Error", error.toJSON());
      }
    }
  },

  displayName: "CreditCardForm",
})(CreditCardFormFields);
