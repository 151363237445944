import React from "react";
import { api } from "../api";

export const useCheckout = () => {
  const [loading, setLoading] = React.useState(false);
  const [checkout, setCheckout] = React.useState(null);
  const [error, setError] = React.useState(null);
  const checkoutID = () =>
    window.location.pathname.split("/").filter(i => !!i)[0];

  const getCheckout = async () => {
    try {
      setLoading(true);
      const response = await api.get(`checkout/${checkoutID()}`);
      setLoading(false);
      setCheckout(response.data);
    } catch (error) {
      setLoading(false);
      setError({ error: error.message });
    }
  };

  return {
    getCheckout,
    checkout,
    loading,
    error,
  };
};
