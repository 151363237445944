export const BILLING_TYPES = {
  BOLETO: "Boleto Bancário",
  CREDIT_CARD: "Cartão de Crédito",
  PIX: "PIX",
};

export const BILLING_TYPE_OPTIONS = {
  creditCard: "CREDIT_CARD",
  boleto: "BOLETO",
  pix: "PIX",
};

export const BILLING_TYPE_OPTIONS_ARRAY = Object.keys(BILLING_TYPE_OPTIONS).map(
  key => [key, BILLING_TYPE_OPTIONS[key]],
);

export const translateBillingType = type => BILLING_TYPES?.[type];
