import React from "react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { CheckoutPropType } from "../utils/CheckoutPropType";
import { translateBillingType } from "../utils/billing_types";

const PriceContainer = styled.div`
  p {
    line-height: 1;
  }
`;

const parseDescription = (str = null) => {
  if (!str) {
    return null;
  }

  return str
    .toString()
    .split("\n")
    .map(i => <p key={i}>{i}</p>);
};

export const CheckoutDetails = ({ checkout = {} }) => {
  const installments = checkout?.installments;

  let lastInstallment = { installment_amount: 0 };
  const totalInstallments = installments?.length;

  if (totalInstallments) {
    lastInstallment = installments[totalInstallments - 1];
  }

  const paymentTypes = checkout?.payment_types;
  const totalPaymentTypes = paymentTypes?.length;

  let orText = `ou R$ ${formatCurrency(parseFloat(checkout?.total))} à vista`;
  let initialText = `Parcele em até ${lastInstallment.installment}x de `;

  // Caso só exista uma forma de pagamento.
  if (totalPaymentTypes === 1) {
    orText = `no ${translateBillingType(paymentTypes[0])}`;
  }

  // Caso só exista um pagamento e apenas 1 parcela disponível
  if (totalPaymentTypes === 1 && totalInstallments === 1) {
    initialText = `${lastInstallment.installment}x de `;
  }

  // Caso existe mais de uma parcela mas não exista a opção de à vista
  if (totalPaymentTypes > 1) {
    const oneXPaymentExist = installments?.find(
      ({ installment }) => installment === 1,
    );

    if (!oneXPaymentExist) {
      orText = "";
    }
  }

  return (
    <div>
      <h5 hidden>Detalhes do produto:</h5>

      <Card body className="mx-3" hidden>
        {parseDescription(checkout?.description)}
      </Card>

      <PriceContainer>
        <p>
          {initialText}
          <strong className="fs-1 text-primary">
            R$ {formatCurrency(parseFloat(lastInstallment?.installment_amount))}
          </strong>{" "}
          {orText}
        </p>
      </PriceContainer>
    </div>
  );
};

CheckoutDetails.propTypes = {
  checkout: CheckoutPropType,
};
